<template>
	<div class="product-marketing">
		<section class="py-4 bg11 mobile-top">
			<div class="container">
				<div class="row">
					<div
						class="col-12 col-sm-12 col-md-10 offset-md-1 col-xs-8 col-sm-8 col-8 col-lg-8 offset-lg-2"
					>
						<h1 class="text-white" v-html="posts[0].title.rendered"></h1>
					</div>
				</div>
			</div>
		</section>

		<div class="container">
			<div class="row">
				<div
					v-if="loading == true && !posts.length"
					class="col-12 col-sm-12 col-md-10 offset-md-1 col-xs-8 col-sm-8 col-8 col-lg-8 offset-lg-2 mb202"
				>
					<div class="flex-box-wrap">
						<div class="flex-internal">
							<atom-spinner
								:animation-duration="1000"
								:size="40"
								:color="'#575757'"
							/>
							<p>Loading......</p>
						</div>
					</div>
				</div>

				<div
					v-else-if="loading == false && !posts.length"
					class="col-12 col-sm-12 col-md-10 offset-md-1 col-xs-8 col-sm-8 col-8 col-lg-8 offset-lg-2 mb20 flexVHcenter"
				>
					<div>
						<img src="@/assets/img/sad.svg" />
						<p>Sorry, nothing was found...</p>
					</div>
				</div>

				<div v-else class="container">
					<div>
						<div class="row">
							<div
								class="col-12 col-sm-12 col-md-10 offset-md-1 col-xs-8 col-sm-8 col-8 col-lg-8 offset-lg-2 mb20 mb20 mt20"
							>
								<router-link
									:to="{ path: $store.state.route.from.fullPath }"
									class="list-btn mb10"
									>Back</router-link
								>
							</div>
						</div>

						<div class="row row-eq-height">
							<div
								v-bind:key="post.id"
								v-for="post in posts"
								class="col-12 col-sm-12 col-md-10 offset-md-1 col-xs-8 col-sm-8 col-8 col-lg-8 offset-lg-2 mb20"
							>
								<div class="post" id="post">
									<div
										v-html="posts[0].content.rendered"
										id="post-content"
									></div>
									<!--
              <button>Read More</button>
                  -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- end other column -->
		</div>
	</div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from "vue-avatar";

//http://mktginfo.spacesaver.com/wp-json/wp/v2/posts
//http://mktginfo.spacesaver.com/wp-json/wp/v2/posts?slug=post-slug

const auth = {
	headers: {
		Authorization: "Bearer spacesaver/ab3f641f910eb693804bc5b097a5bda6",
	},
};

export default {
	name: "ChallengerInsightsSingle",
	components: {
		AtomSpinner,
		Avatar,
	},
	data() {
		return {
			challengerinsights: process.env.VUE_APP_CHALLENGER_INSIGHTS,
			search: "",
			posts: [],
			pageSlug: "",
			loading: true,
			countOfPage: 25,
			currPage: 1,
		};
	},
	methods: {
		firstLoad: function() {
			this.pageSlug = this.$route.params.slug;
			this.loading = true;
			this.$store.commit("setActivePage", "initiatives");
			//return axios.get(this.endPointCompute, auth).then((response) => {
			return axios
				.get(
					`${this.challengerinsights}/wp-json/wp/v2/posts?slug=${this.pageSlug}`
				)
				.then((response) => {
					console.log(response);
					this.posts = response.data;
					this.loading = false;
				});
		},
	},
	computed: {
		activeNav() {
			return this.$store.state.activePage;
		},
		pageStart: function() {
			return (this.currPage - 1) * this.countOfPage;
		},
		totalPage: function() {
			return Math.ceil(this.posts.length / this.countOfPage);
		},
	},
	created() {
		this.$store.commit("showNavigation");
		this.firstLoad();
		//console.log(this.$route.params.slug);
		// console.log(this.$route.path);
	},
	mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.post {
	background: #fff;
	padding: 20px;
	margin-bottom: 20px;
	height: 100%;
}
.post-excerpt {
	font-size: 14px;
}
.post button {
	position: absolute;
	bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background: #00b4b4;
	color: #fff;
	border-radius: 4px;
}

.flex-box-wrap {
	margin-top: 200px;
}
.post-title {
	font-size: 2.5em;
}
</style>
